<template>
  <div id="calendarWrapper">
    <el-button class="thisMonth" @click="renderThisMonth">返回今日</el-button>
    <el-button type="primary" class="editCalendar" v-if='$route.meta.authority.button.save' @click="isEdit = true">{{ $t('button.edit') }}</el-button>
    <el-button class="editCalendarReset" v-show="isEdit" @click="resetHoliday">初始化</el-button>
    <el-button class="editCalendar isEdit" v-show="isEdit" @click="updateHoliday">{{ $t('button.preservation') }}</el-button>
    <calendar height="718px"
              ref="calendar"
              :show-term="false"
              week-title-align="center"
              :render-content="renderContent"
              @date-click="dateClick"
              @year-change="yearChange"
              @month-change="monthChange"
    />
  </div>
</template>

<script>
import Calendar from 'himmas-vue-calendar';

export default {
  name: 'CalendarManagement',
  components: {Calendar},
  inject: ['reload'],
  data() {
    return {
      remarkFocus: false,
      monthChangeState: false,
      currentMonthData: [],
      getHoliday: [],
      OperatorName: null,
      updateHolidayLog: [],
      isEdit: false,
      holiday: [],
      updateHolidayArr: [],
      year: null,
      month: null,
      day: null,
      tableData: [],
      tableCols: [{
        prop: 'date',
        label: '原始日期',
        width: ''
      }, {
        prop: 'weekDay',
        label: '原始星期',
        width: ''
      }, {
        prop: 'state',
        label: '原始状态',
        width: ''
      }, {
        prop: 'changeState',
        label: '更改状态',
        width: ''
      }, {
        prop: 'userName',
        label: '操作账号',
        width: ''
      }]
    };
  },
  created() {
    // 获取当年节假日
    this.queryHoliday(new Date().getFullYear())
    // 获取当前账号：
    this.OperatorName = sessionStorage.userName;
  },
  mounted() {
  },
  methods: {
    remarkChange() {
    },
    editChange() {
      this.isEdit = true
      let remark = document.querySelectorAll('.remark');
      remark.forEach(e => {
        e.addEventListener("click", (e) => {
          e.stopPropagation();
        }, false);
        // e.addEventListener('change', e => {
        //   console.log(e.target.value);
        // })
      })
    },
    renderThisMonth() {
      let year = new Date().getFullYear();
      let mounth = new Date().getMonth() + 1;
      this.$refs.calendar.renderThisMonth(year, mounth);
    },
    excelDown() {
      let excelHtml = `
          <html>
            <head>
              <meta charset='utf-8' />
              <style>
                table {font-size: 14px}
                table td{text-align: center}
              </style>
            </head>
            <body>
              ${this.$refs.excelHtml.innerHTML}
            </body>
          </html>
        `;
      let excelBlob = new Blob([excelHtml], {type: 'application/vnd.ms-excel'});
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth();
      let day = date.getDate();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();
      // 创建一个a标签
      let oA = document.createElement('a');
      // 利用URL.createObjectURL()方法为a元素生成blob URL
      oA.href = URL.createObjectURL(excelBlob);
      // 给文件命名
      oA.download = `${year}${month}${day}${hours}${minutes}${seconds}.xls`;
      // 模拟点击
      oA.click();
    },
    updateHoliday() {
      if (this.updateHolidayLog.length > 0) {
        this.queryUpdateHoliday()
      } else {
        this.$message({
          message: '保存成功！',
          type: 'success'
        });
        this.isEdit = false;
      }
    },
    queryUpdateHoliday() {
      this.updateHolidayArr = [];
      this.updateHolidayLog.forEach(v => {
        let data = {
          holidayDate: `${v.year}-${v.month}-${v.day}`,
          tag: v.tag
        }
        if (v.remark) data.remark = v.remark
        this.updateHolidayArr.push(data);
      });
      let hash = {}; // 去重
      let arr = this.updateHolidayArr.reverse().reduce((preVal, curVal) => {
        hash[curVal.holidayDate] ? "" : (hash[curVal.holidayDate] = true && preVal.push(curVal));
        return preVal;
      }, []);
      this.$axios.post('/acb/2.0/holiday/update', {
        data: {
          params: JSON.stringify(arr)
        }
      })
      .then(() => {
        this.updateHolidayLog = [];
        this.isEdit = false;
        this.reload();
        this.$message({
          message: '保存成功！',
          type: 'success'
        });
        setTimeout(() => {
          this.queryHoliday(this.year);
        }, 500);
      })
      .catch(_ => {
        this.reload();
        this.$message.error('保存失败！');
      });
    },
    resetHoliday() {
      if (this.year != new Date().getFullYear() + 1) return this.$alert('无法对当前或历史年份进行节假日批量初始化处理，请手动配置节假日！');
      this.$confirm('将本年度的周六、周日全部初始化为节假日，周一至周五全部初始化为工作日。是否进行初始化？', this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
        // 请求初始化节假日
        this.holidayRese();
      });
    },
    holidayRese(year) {
      this.$axios.post('/acb/2.0/holiday/reset', {
        data: {
          year: this.year
        }
      })
      .then(() => {
        this.holiday = [];
        this.$message({
          message: '保存成功！',
          type: 'success'
        });
      })
      .catch(() => {
        this.$message.error('保存失败！');
      });
    },
    yearChange(year) {
      let nowYear = new Date().getFullYear();
      if ((year - nowYear) > 1) {
        this.$refs.calendar.renderThisMonth(nowYear + 1, this.month - 1);
        this.queryHoliday(nowYear + 1);
        return false;
      }
      this.monthChangeState = true;
      this.queryHoliday(year);
    },
    monthChange(year, month) {
      let nowYear = new Date().getFullYear();
      if ((this.year - nowYear) > 1) {
        this.$refs.calendar.renderThisMonth(nowYear + 1, 1);
        this.queryHoliday(nowYear + 1);
        return false;
      }
      this.monthChangeState = true;
    },
    queryHoliday(year) {
      this.$axios.get('/acb/2.0/holiday/query', {
        data: {
          year
        }
      })
      .then(res => {
        this.getHoliday = res.value || [];
        this.getHoliday.forEach(v => this.holiday.push(v.holidayDate.substring(0, 10)));
      });
    },
    renderTitle(year, month) {
      return `<div class="kl-calendar_title-bar">
              <div class="kl-calendar_render-info">
                <span class="kl-calendar_year">${year}年</span>
                <span class="kl-calendar_month">${month}月</span>
              </div>
            </div>`;
    },
    dateClick(data) {
      let {
        year,
        month,
        day
      } = data;
      let clickTime = year + this.twoDigit(month) + this.twoDigit(day);
      let nowTime = new Date().getFullYear() + this.twoDigit(new Date().getMonth() + 1) + this.twoDigit(new Date().getDate());
      if (clickTime <= nowTime) return false;
      if (!this.isEdit) return false;
      if (this.monthChangeState) {
        this.currentMonthData.forEach(v => {
          if (v.dateStr === year + '-' + this.twoDigit(month) + '-' + this.twoDigit(day)) {
            data = v;
          }
        });
      }
      this.holidayDataUpdate(data);
    },
    confirmbeforeClose(action, instance, done) {
      if (action !== 'confirm') {
        localStorage.neverNotify = false;
        document.querySelector('#neverNotify').checked = false;
      }
      done();
    },
    holidayDataUpdate(data) {
      let {
        year,
        month,
        day
      } = data;
      let activeDay = `${year}-${this.twoDigit(month)}-${this.twoDigit(day)}`;
      if (this.holiday.indexOf(activeDay) > -1) {
        this.holiday.splice(this.holiday.indexOf(activeDay), 1);
      } else {
        this.holiday.push(`${year}-${this.twoDigit(month)}-${this.twoDigit(day)}`);
      }
      // 点击改变状态
      data.isChange = !data.isChange;
      // 记录改变了没有
      data.isChangeState = true;
      if (data.tag === 1) {
        data.tag = 0;
      } else {
        data.tag = 1;
      }
      if (data.isChange) {
        this.setDate(data)
      } else {
        let DelIndex = this.updateHolidayLog.findIndex(v => v.year == data.year && v.month == data.month && v.day == data.day);
        this.updateHolidayLog.splice(DelIndex, 1);
        let currentMonthDataIndex = this.currentMonthData.findIndex(v => v.year == data.year && v.month == data.month && v.day == data.day);
        this.currentMonthData.splice(currentMonthDataIndex, 1);
      }
      this.updateHolidayLog.sort(this.dateSort);
    },
    setDate(data) {
      let weekDayArr = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
      let {
        year,
        month,
        day
      } = data;
      let dataJson = {
        year,
        month: this.twoDigit(month),
        day: this.twoDigit(day),
        isHoliday: data.tag === 1,
        weekDay: weekDayArr[data.weekDay],
        tag: data.tag
      }
      if (data.remark) dataJson.remark = data.remark
      this.updateHolidayLog.push(dataJson);
      this.currentMonthData.push(data);
      this.currentMonthData = Array.from(new Set(this.currentMonthData));
    },
    dateSort(a, b) {
      let timeA = a.year + '' + this.twoDigit(a.month) + '' + this.twoDigit(a.day);
      let timeB = b.year + '' + this.twoDigit(b.month) + '' + this.twoDigit(b.day);
      return timeA - timeB;
    },
    twoDigit(num) { return ('000' + num).slice(-2); },
    renderContent(h, data) {
      if (typeof data.isChange == 'undefined') data.isChange = false;
      if (typeof data.remark == 'undefined') data.remark = '';
      let {
        isToday,
        isWeekend,
        isOtherMonthDay,
        year,
        day,
        month,
        lunar,
        festival,
        term
      } = data;
      this.year = year;
      this.month = month;
      this.day = day;
      // lunar对象中存有农历数据
      let {lunarDayChiness} = lunar;
      // 第二行展示的数据的优先级为 节日>节气>农历日
      let secondInfo = null;
      if (festival) {
        secondInfo = festival;
      } else {
        secondInfo = term || (lunarDayChiness || '');
      }
      let dateStr = `${year}-${this.twoDigit(month)}-${this.twoDigit(day)}`;
      data.dateStr = dateStr;
      if (this.holiday.indexOf(dateStr) > -1) {
        if (!data.isChange) {
          this.getHoliday.forEach(v => {
            if (v.holidayDate.substring(0, 10) === dateStr) {
              v.tag === 1 ? data.tag = 1 : data.tag = 0;
              if (v.remark && !data.isChangeState) data.remark = v.remark
            }
          });
          if (this.monthChangeState && this.isEdit && this.currentMonthData.length > 0) {
            this.currentMonthData.forEach(v => {
              if (v.dateStr === dateStr) {
                data = v;
              }
            });
          }
        }
      } else {
        if (!data.isChangeState) {
          isWeekend ? data.tag = 1 : data.tag = 0; // 0工作日，1休息日
        }
      }
      let dateBoxClass = `date-box ${isToday ? 'today' : ''} ${data.tag === 1 ? 'holiday' : ''} ${isOtherMonthDay ? 'other-month' : ''}`
      let firstInfoClass = `first-info ${festival ? 'festival' : ''}`
      let clickTime = year + this.twoDigit(month) + this.twoDigit(day);
      let nowTime = new Date().getFullYear() + this.twoDigit(new Date().getMonth() + 1) + this.twoDigit(new Date().getDate());
      let inputDisabled = clickTime <= nowTime;
      let inputShow = (clickTime <= nowTime && !data.remark) ? 'display: none' : '';
      let secondInfoClass = `second-info ${festival ? 'festival' : ''}}`
      let remarksShow = `remark ${this.isEdit || data.remark ? 'remarksShow' : ''}`
      let toDayClass = `${isToday ? 'today' : ''}`
      return (<div class={dateBoxClass}>
        <div class={firstInfoClass}>
          <div class={toDayClass}>{day}</div>
        </div>
        <div class={secondInfoClass}>{secondInfo}</div>
        <input class={remarksShow} style={inputShow} disabled={inputDisabled || !this.isEdit} type="text" oninput= {() => { this.setRemark(data) }} placeholder="请输入备注信息" v-model={data.remark}/>
        <div class="sign">休</div>
        <div class="work">班</div>
      </div>)
    },
    setRemark(data) {
      let {
        year,
        day,
        month,
      } = data;
      let dateStr = `${year}-${this.twoDigit(month)}-${this.twoDigit(day)}`;
      if (this.holiday.indexOf(dateStr) > -1) {
        this.getHoliday.forEach(v => {
          if (v.holidayDate.substring(0, 10) === dateStr) {
            if (v.remark) v.remark = data.remark
          }
        });
      }
      this.setDate(data)
    }
  }
};
</script>
<style>
.neverNotifyWrapper {
  width: 558px;
}

.neverNotifyWrapper .neverNotify {
  position: absolute;
  bottom: -36px;
  left: 48px;
}
</style>
<style scoped>
#calendarWrapper >>> .kl-calendar_body-day .date-box:hover {
  border: 2px solid #0099fa;
}

#calendarWrapper >>> .kl-calendar .kl-calendar_body .kl-calendar_body-week-title {
  border-bottom: none;
  height: 44px;
  background: #E5E9F2;
}

#calendarWrapper >>> .kl-calendar .kl-calendar_body .kl-calendar_body-week-title .kl-calendar_body-week-title-item {
  line-height: 44px;
  font-size: 14px;
  color: #475669;
}

.editCalendar, .editCalendarReset {
  position: absolute;
  right: 2%;
  top: 24px;
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  z-index: 2;
}

.thisMonth {
  position: absolute;
  right: 22%;
  top: 24px;
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  z-index: 2;
}

.editCalendarReset {
  right: 12%;
}

.editCalendar.isEdit {
  background: #FFA924;
  border: 1px solid #FFA924;
}

#calendarWrapper {
  position: relative;
}

#calendarWrapper >>> .kl-calendar_title-bar {
  height: 84px;
  line-height: 84px;
  background: #EFF2F7;
  text-align: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}

#calendarWrapper >>> .kl-calendar_title-bar .kl-calendar_render-info span {
  font-size: 16px;
  color: #475669;
  line-height: 84px;
}

#calendarWrapper >>> .kl-calendar_title-bar .kl-calendar_render-info span:nth-of-type(1) {
  margin-right: 34px;
}

#calendarWrapper >>> .kl-calendar .kl-calendar_title-bar .kl-calendar_render-info {
  font-size: 0;
  line-height: initial;
  min-width: 115px;
  text-align: right;
  padding-right: 50px;
}

#calendarWrapper >>> .kl-calendar_body {
  user-select: none;
}

#calendarWrapper >>> .kl-calendar_tool {
  width: 50%
}

#calendarWrapper >>> .kl-calendar_tool-btn {
  border: none;
  line-height: 60px;
  height: 60px;
  padding: 0 6px;
  text-indent: -9999px;
  width: 16px;
  overflow: hidden;
}

#calendarWrapper >>> .kl-calendar_tool-btn:nth-of-type(1) {
  background: url("./img/leftS.png") no-repeat 0 center;
}

#calendarWrapper >>> .kl-calendar_tool-btn:nth-of-type(2) {
  background: url("./img/left.png") no-repeat 0 center;
}

#calendarWrapper >>> .kl-calendar_tool-btn:nth-of-type(3) {
  text-indent: 0px;
  width: 50px;
}

#calendarWrapper >>> .kl-calendar_tool-btn:nth-of-type(4) {
  background: url("./img/left.png") no-repeat 0 center;
  transform: rotate(180deg);
}

#calendarWrapper >>> .kl-calendar_tool-btn:nth-of-type(5) {
  background: url("./img/leftS.png") no-repeat 0 center;
  transform: rotate(180deg);
}

#calendarWrapper >>> .date-box {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
#calendarWrapper >>> .remark {
  display: none;
}
#calendarWrapper >>> .remark.remarksShow {
  flex: 1;
  display: flex;
  justify-content: center;
  color: #999;
  font-size: 12px;
  align-items: center;
  text-align: center;
  border: none;
  border-top: 1px solid #ddd;
}
#calendarWrapper >>> .first-info {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 14px;
  color: #999999;
}

#calendarWrapper >>> .date-box.today .today {
  background: #f44336;
  border-radius: 50%;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;

}

#calendarWrapper >>> .second-info {
  flex: 1;
  display: flex;
  justify-content: center;
  color: #999;
  font-size: 12px;
  align-items: center;
}
#calendarWrapper >>> .secondInfoShow {
  display: none;
}

#calendarWrapper >>> .second-info.festival {
  color: #FC7824;
}

#calendarWrapper >>> .first-info.festival {
  color: #fc7824;
}

#calendarWrapper >>> .sign {
  display: none;
  position: absolute;
  top: 4px;
  right: 4px;
  background: #F7B62D;
  width: 20px;
  height: 20px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
}

#calendarWrapper >>> .work {
  position: absolute;
  top: 4px;
  right: 4px;
  background: #338EED;
  width: 20px;
  height: 20px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
}

#calendarWrapper >>> .kl-calendar .kl-calendar_body .kl-calendar_body-week:nth-of-type(2n+1) {
  /*background: #F9FAFC;*/
}

#calendarWrapper >>> .holiday .sign {
  display: block;
}

#calendarWrapper >>> .holiday .work {
  display: none;
}

#calendarWrapper >>> .getholiday .sign {
  display: block !important;
}

#calendarWrapper >>> .getholiday .work {
  display: none !important;
}

#calendarWrapper >>> .date-box.other-month .first-info {
  color: #999;
}

#calendarWrapper >>> .date-box.other-month .second-info {
  color: #ccc;
}

#calendarWrapper >>> .date-box.other-month {
  background: #ececec85;
}

#calendarWrapper >>> .title-box {
  font-size: 20px;
}

.updateHolidaylayer {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
}

.updateHolidaylayerWrapper {
  width: 650px;
  position: absolute;
  left: 50%;
  top: 20%;
  margin-left: -325px;
  padding: 20px 40px;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.updateHolidaylayerTitile {
  font-size: 24px;
  line-height: 46px;
  position: relative;
}

.updateHolidaylayerTitile span {
  position: absolute;
  right: 10px;
  top: 0;
}

.updateHolidaylayerTitile span button {
  height: 34px;
  padding: 4px 14px;
  margin-left: 10px;
  background: #0099fa;
  color: #fff;
}

.updateHolidaylayerContent {
  padding: 20px 10px;
  text-align: center;
  max-height: 320px;
  line-height: 24px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  margin: 20px 0;
}

.updateHolidaylayerContent .workColor {
  color: #0099fa;
}

.updateHolidaylayerContent .workColor.updateHolidayColor {
  color: #ff9a01;
}

.updateHolidaylayerBotBtn {
  text-align: center;
}
</style>
<style>
.el-button--small {
  float: right;
  margin-left: 10px;
}
</style>
